@import '../global/configurable-values';

.lv-settings-side-menu {
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 64px);
  background: $background-tux-darkest;
}

.lv-settings-title {
  padding: 1rem;
  font-size: 1.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.lv-settings-section-title {
  padding-top: 1rem;
  font-size: 2.188rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.lv-settings-section-subtitle {
  padding: 0.5rem 0;
  font-size: 1.313rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.lv-settings-menu-item {
  cursor: pointer;
  padding: 2rem 1rem 2rem 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: $nuetrals-white;
  &:hover {
    color: $tint-primary-50;
  }
}

.lv-settings-menu-item.selected {
  cursor: pointer;
  padding: 2rem 1rem 2rem 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: $nuetrals-white;
  background: $tux;
  &:hover {
    color: $tint-primary-50;
  }
}

.lv-settings-container {
  height: calc(100% - 64px);
  width: 100%;
  min-height: calc(100vh - 64px);
  background: $tux;
}

.lv-settings-section {
  margin-left: 12rem;
  width: 100%;
}

.lv-setting-member-section {
  width: 50vw;
}

.lv-setting-member-title {
  font-size: 1.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  color: $nuetrals-white;
}

.lv-setting-member-name {
  width: 200px;
  font-size: 1.313rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: $nuetrals-white;
}

.lv-setting-member-role {
  max-width: 120px;
  width: auto;
  color: $nuetrals-white !important;
  padding: 5px !important;
  border: solid 2px $nuetrals-white !important;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px !important;

  .mat-select-arrow {
    color: $nuetrals-white !important;
    font-size: 1.063rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
  }

  .mat-select-value {
    color: $nuetrals-white !important;
    font-size: 1.063rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    padding-right: 0.5rem;
  }

  .mat-option:hover {
    background: $tint-primary-50;
  }
}
