@use '@angular/material' as mat;
@import '@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNext/400-Regular/avenir-next-regular.svg')
      format('svg'),
    url('../../fonts/AvenirNext/400-Regular/avenir-next-regular.woff')
      format('woff'),
    url('../../fonts/AvenirNext/400-Regular/avenir-next-regular.woff2')
      format('woff2'),
    url('../../fonts/AvenirNext/400-Regular/avenir-next-regular.ttf')
      format('ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNext/500-Medium/avenir-next-medium.svg')
      format('svg'),
    url('../../fonts/AvenirNext/500-Medium/avenir-next-medium.woff')
      format('woff'),
    url('../../fonts/AvenirNext/500-Medium/avenir-next-medium.woff2')
      format('woff2'),
    url('../../fonts/AvenirNext/500-Medium/avenir-next-medium.ttf')
      format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNext/600-Demi/avenir-next-demi.woff')
      format('woff'),
    url('../../fonts/AvenirNext/600-Demi/avenir-next-demi.woff2')
      format('woff2'),
    url('../../fonts/AvenirNext/600-Demi/avenir-next-demi.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNext/700-Bold/avenir-next-bold.woff')
      format('woff'),
    url('../../fonts/AvenirNext/700-Bold/avenir-next-bold.woff2')
      format('woff2'),
    url('../../fonts/AvenirNext/700-Bold/avenir-next-bold.ttf') format('ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

//CONFIG =>  size , line-height , weight, font , spacing
$fontConfig: (
  display-4:
    mat.define-typography-level(112px, 112px, 300, 'AvenirNext', -0.0134em),
  display-3:
    mat.define-typography-level(56px, 56px, 400, 'AvenirNext', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'AvenirNext', 0em),
  display-1:
    mat.define-typography-level(34px, 40px, 400, 'AvenirNext', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'AvenirNext', 0em),
  title: mat.define-typography-level(20px, 32px, 500, 'AvenirNext', 0.0075em),
  subheading-2:
    mat.define-typography-level(16px, 28px, 400, 'AvenirNext', 0.0094em),
  subheading-1:
    mat.define-typography-level(15px, 24px, 500, 'AvenirNext', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'AvenirNext', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'AvenirNext', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'AvenirNext', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'AvenirNext', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'AvenirNext', 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

mat.$light-theme-foreground-palette: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

mat.$dark-theme-foreground-palette: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#171717, 20%);
$dark-bg-alpha-4: rgba(#171717, 0.04);
$dark-bg-alpha-12: rgba(#171717, 0.12);

mat.$light-theme-background-palette: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #171717;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config
body {
  --primary-color: #800202;
  --primary-lighter-color: #fde3c4;
  --primary-darker-color: #f58825;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (
  main: #800202,
  lighter: #fde3c4,
  darker: #f58825,
  200: #800202,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #555659;
  --accent-lighter-color: #cccccd;
  --accent-darker-color: #3b3c3e;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #555659,
  lighter: #cccccd,
  darker: #3b3c3e,
  200: #555659,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #c96664;
  --warn-lighter-color: #efd1d1;
  --warn-darker-color: #b54948;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #c96664,
  lighter: #efd1d1,
  darker: #b54948,
  200: #c96664,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'AvenirNext';
  }
}
