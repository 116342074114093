@import './configurable-values';

.lv-tab-active {
  color: $tint-primary;
}

.mat-tab-label-active {
  color: $tint-primary !important;
  font-weight: bold !important;
}

.lv-tab-group {
  width: 100%;
  background: $background-tux-lightest;
  margin-bottom: 5px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;

  @include xs {
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }

  @include sm {
    padding-left: 25px;
    padding-right: 25px;
  }

  @include md {
    padding-left: 75px;
    padding-right: 75px;
  }

  @include lg {
    padding-left: 200px;
    padding-right: 200px;
  }

  @include xl {
    padding-left: 256px;
    padding-right: 256px;
  }

  div {
    margin-right: 20px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .lv-tab {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: $nuetrals-white;
  }

  .lv-tab-active {
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: $tint-primary;
    border-bottom: 2px solid;
  }
}
