@import 'mixins';
@import './configurable-values';

.lv-offer-footer {
  margin: 0;
  width: 100%;
  border-top: 1px solid $nuetrals-grey-5;
  text-align: center;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  height: $footer-height;
  box-sizing: border-box;
}

.lv-footer-wrapper {
  display: grid;
  grid-template-columns: 289px 1fr 289px;
  align-items: center;
  margin: 0 auto;
  height: 100%;

  button {
    max-width: 153px;
    height: 3rem;
  }

  @include xs {
    max-width: calc(100% - 10px);
  }

  @include sm {
    max-width: calc(100% - 50px);
  }

  @include md {
    max-width: calc(100% - 150px);
  }

  @include lg {
    max-width: calc(100% - 200px);
  }

  @include xl {
    max-width: $maxWidth;
  }
}
