@import 'configurable-values';
@import 'mixins';
body {
  margin: 0;
  background: $nuetrals-white;
  color: $nuetrals-black-pure;
}

.page-wrapper {
  background-color: $nuetrals-white;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.page-container {
  width: 100%;
  max-width: 1080px;
  height: 100%;
  &-xxl {
    max-width: 1536px;
  }
}

.page-container-light {
  // width: 100%;
  // height: 100%;
  // min-height: 93vh;
  // margin: 0;
  // background-color: $nuetrals-white !important;
  color: $nuetrals-black-pure !important;
}

.page-content {
  height: 100%;
  width: 100%;
  margin: auto;
}

.page-title-section {
  // background: $background-tux-lightest;
  // padding: 0 10%;
  padding-top: 48px;
  padding-bottom: 24px;
  box-sizing: border-box;
  &-offer-detail,
  &-results {
    padding-top: 0;

    .page-title-text {
      padding-left: 0;
      width: 100%;
      color: $nuetrals-black-pure;
    }
  }
}

.page-title-start {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 2rem;
}

.page-title-text {
  // padding-left: 25px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  box-sizing: border-box;
  // width: calc(100% - 112px);

  .page-title-h1 {
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 42px;
    letter-spacing: normal;
    margin-bottom: 0px;
    margin-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .page-title-subheader {
    font-size: 21px;
    margin-top: 0px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}

.page-title-brand-logo {
  width: 112px;
  height: 112px;
  background-color: $nuetrals-white;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  img {
    max-height: 90px;
    max-width: 90px;
  }
}


.page-container-with-footer-light {
  width: 100%;
  height: 100%;
  height: calc(100vh - $footer-height);
  overflow-y: auto;
  margin: 0;
  background-color: $nuetrals-white !important;
  color: $nuetrals-black-pure !important;
}
