.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: normal;
}

.text-bolder {
  font-weight: bolder;
}

.cursor-pointer {
  cursor: pointer;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-100 {
  padding-top: 100px;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-25 {
  padding-right: 25px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-left-25 {
  padding-left: 25px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-right-100 {
  padding-right: 100px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-left-25 {
  margin-left: 25px;
}

.full-width {
  width: 100%;
}

// AG grid styles

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
@import '../assets/scss/configurable-values.scss';
.ag-row {
  border-bottom: none;
  &-odd {
    background-color:#f5f5f5;
  }
  .ag-cell {
    color: $nuetrals-black-pure;
  }
  &-hover {
    &::before {
      background-color:#f5f5f5 !important;
    }
  }
}
.ag-root-wrapper {
  &-body.ag-layout-normal {
    height: auto;
  }
  .ag-cell-label-container {
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    color: $nuetrals-black-pure;
  }
} 
.leaf-offer-name {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $nuetrals-white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.ag-theme-alpine-dark {
  --ag-background-color: unset;
  --ag-header-background-color: unset;
  --ag-border-color: unset;
  --ag-odd-row-background-color: #202636;
  // Reduces padding between columns
  --ag-cell-horizontal-padding: 2px;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  font-size: 14px;
  .action-button {
    width: auto;
    height: auto;
    line-height: normal;
    color: $nuetrals-black-pure;
    .mat-icon {
      height: 16px;
      width: 16px;
      line-height: 16px;
    }
  }
}

.ag-header {
  &-cell {
    padding-left: 0; padding-right: 0;
  }
}

.leaf-offer-avatar {
  display: flex;
  width: 2rem;
  height: 2rem;
  margin-left: 24px;
  background-color: $nuetrals-white;
  text-align: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
  object-fit: cover;

  img {
    object-fit: cover;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.action-button {
  color: #FFF;
}
