@import 'configurable-values';
@import 'mixins';

.lv-breadcrumb {
  height: 52px; margin-bottom: 24px;
  button {
    color: $nuetrals-black-pure;
    padding: 0;
    font-family: "Avenir Next";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    .mat-icon {
      font-size: 30px;
      width: 30px;
      height: 30px;
    }
  }
}
