@import 'configurable-values';

// Extra Small devices
@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin step-container($padding: 0 64px 0 64px) {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: $padding;

  @include xs {
    max-width: calc(100% - 20px);
  }

  @include sm {
    max-width: calc(100% - 50px);
  }

  @include md {
    max-width: calc(100% - 150px);
  }

  @include lg {
    max-width: calc(100% - 200px);
  }

  @include xl {
    max-width: $maxWidth;
  }
}
