@import 'configurable-values';
@import 'mixins';

.bg-grey-table-row {
  background: $nuetrals-grey-6 !important;
}

.lv-dispensary-table-container {
  max-height: 350px;
  overflow: auto;
  width: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lv-dispensary-table-container::-webkit-scrollbar {
  display: none;
}

.lv-dispensary-table {
  width: 100%;
  background: $nuetrals-white !important;
  color: $tux !important;

  .lv-header-text {
    font-size: 14px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lv-dispensary-table-select-button {
    color: $nuetrals-black-pure !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 0;
    padding-left: 0;
  }

  :disabled {
    color: $dark-grey !important;
  }

  .lv-dispensary-table-disabled-row {
    opacity: 0.3;
    cursor: default;
  }

  // Background border
  .mat-checkbox {
    padding-right: 10px;
  }

  .mat-checkbox .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: $nuetrals-white;
  }

  .mat-table {
    background: $nuetrals-white !important;
    color: $nuetrals-black-pure !important;
  }

  .mat-cell,
  .mat-footer-cell {
    color: $nuetrals-black-pure !important;
  }

  .mat-header-cell {
    color: $nuetrals-black-pure !important;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 1rem;
  }

  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 1rem;
  }
}

.lv-dispensary-table-name {
  font-size: 16px;
  line-height: 21px;
}

.lv-dispensary-table-address {
  font-size: 14px;
  line-height: 16px;
  color: $dark-grey;
}

.lv-table-container {
  margin-top: 25px;
  width: 100%;
  height: 100%;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  table {
    th {
      background: $tux;
      color: $nuetrals-white;
      font-size: 0.875rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
    }

    width: 100%;
    max-height: 100%;

    @include xs {
      min-width: 95%;
    }

    @include sm {
      min-width: 90%;
    }

    @include md {
      min-width: 80%;
    }

    @include lg {
      min-width: 80%;
    }

    @include xl {
      min-width: 80%;
    }
  }
}

.lv-product-table-container {
  margin-top: 0.5rem;
  max-height: 325px;
  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: 0 !important; /* Firefox */
}

.lv-product-table-container::-webkit-scrollbar {
  display: none !important;
}

.lv-product-table-selection-container {
  margin-top: 0.5rem;
  max-height: 450px;
  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: 0 !important; /* Firefox */
}
.lv-product-table-selection-container-table-container::-webkit-scrollbar {
  display: none !important;
}

.lv-product-table {
  width: 100%;
  background: $nuetrals-white !important;
  color: $tux !important;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 0; /* Firefox */

  .lv-header-text {
    font-size: 14px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lv-product-table-select-button {
    color: $nuetrals-black-pure !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 0;
    padding-left: 0;
  }

  :disabled {
    color: $dark-grey !important;
  }

  // Background border
  .mat-checkbox {
    padding-right: 10px;
  }

  .mat-checkbox .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: $nuetrals-white;
  }

  .mat-table {
    background: $nuetrals-white !important;
    color: $nuetrals-black-pure !important;
  }

  .mat-cell,
  .mat-footer-cell {
    color: $nuetrals-black-pure !important;
  }

  .mat-header-cell {
    color: $nuetrals-black-pure !important;
  }

  table {
    th {
      background: $nuetrals-white !important;
      color: $nuetrals-black-pure !important;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }

    td {
      background: $nuetrals-white !important;
      color: $nuetrals-black-pure !important;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }
  }

  .mat-column-select {
    &:first-of-type {
      padding-left: 15px;
    }
  }
}

/** Employee Table  */
.lv-employee-table-container {
  height: 19.625rem;
  flex-shrink: 1;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lv-employee-table-container::-webkit-scrollbar {
  display: none;
}

.lv-employee-table {
  width: 100%;
  background: $nuetrals-white !important;
  color: $tux !important;

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 1rem;
  }

  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 1rem;
  }

  .lv-header-text {
    font-size: 14px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lv-employee-table-select-button {
    color: $nuetrals-black-pure !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 0;
    padding-left: 0;
  }

  .lv-employee-table-check-button {
    width: 1rem;
    padding-left: 1rem !important;
  }

  .lv-employee-table-remove-item {
    font-size: 1.75rem;
    width: 1.75rem;
    height: 1.75rem;
  }

  :disabled {
    color: $dark-grey !important;
  }

  // Background border
  .mat-checkbox {
    padding-right: 10px;
  }

  .mat-checkbox .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: $nuetrals-white;
  }

  .mat-table {
    background: $nuetrals-white !important;
    color: $nuetrals-black-pure !important;
  }

  .mat-cell,
  .mat-footer-cell {
    color: $nuetrals-black-pure !important;
  }

  .mat-header-cell {
    color: $nuetrals-black-pure !important;
  }

  table {
    th {
      background: $nuetrals-white !important;
      color: $nuetrals-black-pure !important;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }

    td {
      background: $nuetrals-white !important;
      color: $nuetrals-black-pure !important;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }
  }

  .lv-employee-td-check {
    width: 1rem;
    padding-left: 1rem !important;
  }
}

.lv-employee-table-name {
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
}

.lv-employee-table-role {
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
  font-weight: bold;
}

.lv-employee-table-address {
  font-size: 14px;
  line-height: 16px;
  color: $dark-grey;
}

.lv-employee-table-role-type {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  color: $dark-grey;
  text-align: right;
  text-transform: capitalize;
}

.lv-employee-role-column {
  padding-right: 0 !important;
  text-align: right !important;
}

.lv-result-table {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lv-offer-table {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;

  table {
    width: 100%;
    height: 100%;

    .mat-row {
      height: 70px;
    }

    thead {
      tr {
        background: $tux;
        height: 4rem;
        align-items: flex-end;
      }
    }

    tr {
      display: grid;
      grid-template-columns: 35% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      height: 3.5rem;
    }

    th {
      background: $tux;
      color: $nuetrals-white;
      font-size: 0.925rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: center;
      vertical-align: bottom;
      padding: 0 0.15625rem 0.688rem;
      box-sizing: border-box;
      text-align: center;
      border: 0;

      .mat-sort-header-container {
        justify-content: center;
      }

      &:first-child {
        .mat-sort-header-container {
          justify-content: flex-start;
        }
      }

      &:last-child {
        padding-left: 0;
        padding-right: 1rem;
      }
    }

    td {
      color: $nuetrals-white;
      font-size: 0.925rem;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: default;
      padding: 0 2.5px;
      border: 0;

      .truncate {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:first-child {
        text-align: left;
      }

      &:last-child {
        padding-left: 0;
        padding-right: 1rem;
      }

      button {
        color: $tint-primary;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media only screen and (min-width: 1300px) {
      tr {
        grid-template-columns: 40% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
      }
    }

    @media only screen and (min-width: 1400px) {
      tr {
        grid-template-columns: 47% 1fr 1fr 1fr 15% 6.875rem 2.5rem;
      }
    }
  }
}

.mat-tooltip {
  &.table-tooltip {
    color: $nuetrals-black-pure;
    background-color: $nuetrals-white;
    font-size: 1rem;
    line-height: 1.2;
    padding: 1rem;
    text-transform: capitalize;
  }
}

.leaf-offer-name {
  cursor: pointer;
  font-size: 0.925rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $nuetrals-white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.hide-column-sm {
  @include sm {
    visibility: hidden !important;
  }
}

.lv-results-favorite-table {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;

  .lv-result-response {
    width: 22rem;
    font-size: 0.875rem;
    line-height: 1rem;
    color: $nuetrals-white;
    padding: 10px 0;
  }

  .lv-result-dispensary {
    width: 6rem;
  }

  table {
    width: 100%;
    color: $nuetrals-white;

    th {
      background: $tux;
      color: $nuetrals-white;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal;
    }

    td {
      color: $nuetrals-white;
      font-size: 0.875rem;
      font-weight: normal;
    }
  }
}

// Products

.lv-products-list-container {
  // ToDo: Need to make this responsive
  width: 404px;
  height: 500px;
  border-radius: 16px;
  border: solid 2px $nuetrals-grey-5;
  background-color: $nuetrals-white;
  padding-top: 1rem;

  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-products-table-container {
  max-height: 350px;
  overflow: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.lv-products-table-container::-webkit-scrollbar {
  display: none;
}

.lv-products-table {
  width: 100%;
  background: $nuetrals-white !important;
  color: $tux !important;

  .lv-header-text {
    font-size: 14px;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lv-products-table-select-button {
    color: $nuetrals-black-pure !important;
    font-size: 14px;
    font-weight: bold;
    margin-left: 0;
    padding-left: 0;
  }

  :disabled {
    color: $dark-grey !important;
  }

  // Background border
  .mat-checkbox {
    padding-right: 10px;
  }

  .mat-checkbox .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: $nuetrals-white;
  }

  .mat-table {
    background: $nuetrals-white !important;
    color: $nuetrals-black-pure !important;
  }

  .mat-cell,
  .mat-footer-cell {
    color: $nuetrals-black-pure !important;
    height: 4rem;
  }

  .mat-header-row {
    height: 28px !important;
  }

  .mat-header-cell {
    color: $nuetrals-black-pure !important;
  }

  table {
    th {
      background: $nuetrals-white !important;
      color: $nuetrals-black-pure !important;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }

    td {
      background: $nuetrals-white !important;
      color: $nuetrals-black-pure !important;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }
  }

  .lv-trash-column {
    padding-right: 0 !important;
    padding-left: 5rem !important;
    text-align: right !important;
  }
}

.lv-products-table-name {
  font-size: 16px;
  line-height: 21px;
  text-transform: capitalize;
}

.lv-products-table-subtitle {
  font-size: 14px;
  line-height: 17px;
  color: $nuetrals-black-pure;
  text-transform: capitalize;
}

.lv-products-table-in-stock {
  font-size: 14px;
  line-height: 17px;
  color: $dark-grey;
}
