@import '../global/configurable-values';
@import '../global/mixins';

.lv-metrics-container {
  // background: linear-gradient(
  //   to bottom,
  //   $background-tux-lightest 0%,
  //   $background-tux-lightest 50%,
  //   $nuetrals-black-pure 50%,
  //   $nuetrals-black-pure 100%
  // );
  // padding: 0 10%;
}

.lv-leader-metrics-container {
  background: linear-gradient(
    to bottom,
    $background-tux-lightest 0%,
    $background-tux-lightest 50%,
    $tux 50%,
    $tux 100%
  );
  padding: 0 10%;
}

.lv-leader-metrics-child {
  margin-top: 24px;
  background: none;
}

.lv-leader-metrics-row {
  width: 100%;

  //@include xs {
  //  padding-left: 10px;
  //  padding-right: 10px;
  //  min-height: 125px;
  //}
  //
  //@include sm {
  //  padding-left: 25px;
  //  padding-right: 25px;
  //  min-height: 125px;
  //}
  //
  //@include md {
  //  padding-left: 75px;
  //  padding-right: 75px;
  //  min-height: 125px;
  //}
  //
  //@include lg {
  //  padding-left: 200px;
  //  padding-right: 200px;
  //  min-height: 125px;
  //}
  //
  //@include xl {
  //  padding-left: 256px;
  //  padding-right: 256px;
  //  min-height: 125px;
  //}
}

.lv-metric-card {
  cursor: pointer;
  background: rgba($tint-primary, 0.4);
  border-radius: 12px;
  width: 50%;
  max-height: 109px;
  padding: 15px;
  font-family: AvenirNext, sans-serif;
}

.lv-metric-title {
  font-size: 16px;
  color: $nuetrals-black-pure;
  font-weight: bold;
  line-height: 21px;
}

.lv-metric-value-container {
  color: $nuetrals-black-pure;

  .mat-icon {
    height: auto;
    width: auto;
    font-size: 32px;
    margin-top: 10px;
  }
}
.lv-metric-subvalue {
  font-size: 23px;
  line-height: normal;
  margin-top: 20px;
  font-weight: bold;
}

.lv-metric-value {
  font-size: 50px;
  line-height: 65px;
  font-weight: bold;
}

.lv-metric-icon {
  width: 32px !important;
  height: 32px !important;
}

.lv-stat-card {
  background: $background-tux-darkest;
  border-radius: 16px;
  width: 50%;
  max-height: 109px;
  padding: 15px;
}

.lv-stat-card-wide {
  background: $background-tux-darkest;
  border-radius: 16px;
  width: 90%;
  max-height: 109px;
  padding: 15px;
}

.lv-stat-value-container {
  color: $tint-primary;

  .mat-icon {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 2.5rem;
    margin-left: 0.375rem;
  }
}

.lv-stat-subvalue {
  color: $tint-primary;
  font-size: 0.875rem;
  line-height: 1.29;
  margin-top: 1rem;
  font-weight: bold;
}

.lv-stat-title {
  font-size: 20px;
  color: $nuetrals-white;
  font-weight: bold;
  line-height: 26px;
  padding-bottom: 10px;
}

.lv-stat-value {
  color: $nuetrals-black-pure;
  font-size: 50px;
  line-height: 65px;
  font-weight: bold;
}

.lv-stat-icon {
  width: 32px !important;
  height: 32px !important;
}

.lv-details-nav {
  margin: auto;
  margin-top: 60px;
  margin-bottom: 30px;
  // width: $content-width;
}

.lv-details-title {
  color: #626262;
  font-size: 22px;
  line-height: 29px;
  font-weight: bold;
}

.lv-detail-tab {
  padding:15px 0px;
  cursor: pointer;
  color: #626262;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  border-bottom: 2px solid transparent;
}

.lv-detail-tab-active {
  color: $nuetrals-black-pure;
  border-bottom: 2px solid $nuetrals-black-pure;
}

.lv-filter-row {
  // margin-bottom: 10px;

  .mat-menu {
    background: $nuetrals-white;
    color: $nuetrals-black-pure;
  }
}

.lv-filter-response {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 14px 10px;
  color:  $nuetrals-black-pure;
  cursor: pointer;
  border: 2px solid  $nuetrals-black-pure;
  position: relative;

  .mat-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 33px;
    width: auto;
    height: auto;
    margin-right: 0 !important;
  }

  input[type='text'] {
    cursor: pointer;
    color: $nuetrals-black-pure;
    border: none;
    outline: none;
    font-family: AvenirNext, sans-serif;
    font-size: 19px;
    font-weight: 700;
    padding: 0;
    line-height: 26px;
    width: 172px;
    padding-left: 35px;

    &::placeholder {
      color: $nuetrals-black-pure;
      opacity: 1;
    }
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $nuetrals-white;
  }

  input[type='text']:focus {
    outline: none;
    color: $nuetrals-white;
    border: none;
  }
}
.filterBtn {
  .mat-button {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    padding: 14px 10px;
    color:  $nuetrals-black-pure;
    cursor: pointer;
    border: 2px solid  $nuetrals-black-pure;
    line-height: 24px;
    min-width: 122px;
    text-align: left;
    position: relative;
    
    .mat-button-wrapper {
      font-size: 19px;
      font-weight: 700;
    }
    .mat-icon {
      position: absolute;
      right: 5px;
      font-size: 33px;
      width: auto;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.lv-filter-title {
  color: $nuetrals-black-pure;
  min-width: 250px;
}

.mat-menu-panel {
  -ms-overflow-style: none !important; /* IE and Edge */
  width: 260px;
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.lv-nested-menu {
  background: $nuetrals-white !important;
  color: $tux !important;

  .mat-menu-item {
    background: transparent;
    color: $tux !important;
  }

  .mat-menu-item-submenu-trigger::after {
    background: transparent;
    color: $tux !important;
  }

  form {
    color: $tux !important;
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }
  }
}

.lv-leader-date-range-form {
  margin-x: auto;
  margin-left: 1rem;

  .lv-date-range-title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  mat-form-field {
    width: calc(100% - 10px);
  }

  button {
    text-align: center;
    width: calc(100% - 5px);
    margin-bottom: 1rem;
  }
}
