.advanced-pie-legend .total-value {
  padding-bottom: 10px;
}

.advanced-pie-legend .legend-items-container .legend-items {
  overflow: visible !important;
  white-space: nowrap;
}

.advanced-pie-legend
  .legend-items-container
  .legend-items
  .legend-item
  .item-value {
  padding-bottom: 5px;
}
